.table-styled {
    /*border-radius: 15px;*/
    /*box-shadow: 5px 5px 10px rgba(0,0,0,0.3);*/
    /*margin: 0 20px;*/
}

@media (max-width: 900px) {
    .table-styled {
        margin: 0 0px;
    }
}

.ant-table-cell{
    text-align: center;
}

@media only screen and (max-width: 600px) {
    /* styles for small screens */
    .ant-table {
        width: 100%;
        overflow-x: auto;
    }
    /* styles for small screens */
}

.green-text {
    color: green;
    font-weight: bold;
    /*transition: color 1s ease;*/
}

.black-text {
    color: black;
    transition: color 1s ease;
}

.green-header .ant-table-thead > tr > th {
    background-color: green;
    color: white;
    font-weight: bold;
}

.red-header .ant-table-thead > tr > th {
    background-color: red;
    color: white;
    font-weight: bold;
}

.blue-header .ant-table-thead > tr > th {
    background-color: blue;
    color: white;
    font-weight: bold;
}
