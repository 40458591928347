/* BaseView.css */

.image-overlay-container {
    position: relative;
    width: 100%;
    height: 100%; /* You can adjust the height as needed */
    min-height: 100vh;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0; /* Set the z-index to ensure it is behind other elements */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Set the z-index to be above the background image */
}

.content {
    position: relative;
    z-index: 2; /* Set the z-index to be above the overlay */
}
