.article-container {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 50px;
}

/* styles.css */
.article-title {
    font-size: 24px;
    font-weight: bold;
    background: linear-gradient(45deg, #1a237e, #2e7d32, #6a1b9a, #d84315, #0d47a1);
    background-size: 200% 200%;
    animation: gradientAnimation 6s ease infinite;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.cover-image {

}

.prefer-listening {
    font-size: 10px;
    text-align: center;
    font-style: italic;
}

.by-line {

}

.date-time {

}

.sub-heading {
    text-align: center;
    margin: 30px;
}

.audio-player {
    width: 100%;
}

.youtube-video-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.youtube-video-iframe {
    width: 700px;
    height: 315px;
}

.article-content {
    margin-top: 20px;
}



@media (min-width: 768px) {
    .article-container {
        margin-left: 200px;
        margin-right: 200px;
        margin-top: 70px;
    }
}
