.margin-before-options {
    margin: 150px 0;
}


.top-margin {
    margin: 40px 0;
}


.option-margin {
    margin: 0 20px;
}

@media (min-width: 1082px) {
    .margin-before-options {
        margin: 150px 0;
    }
    .top-margin {
        margin: 100px 0;
    }
    .option-margin {
        margin: 0 200px;
    }
}
