.about-page {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 20px 100px;
}

@media (min-width: 768px) {
    .about-page {
        padding: 0 80px 100px;
    }
}
