
.api-option-margin {
    margin: 0 20px;
}

@media (min-width: 1082px) {
    .api-option-margin {
        margin: 0 0;
    }
}
