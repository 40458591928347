.moving-text-container {
    width: 100%;
    height: 100%;
    background-color: black;
    overflow: hidden;
}

.moving-text {
    font-size: 20px;
    color: white;
    white-space: nowrap;
    position: relative;
    animation: move 10s linear infinite;
}

@keyframes move {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}
