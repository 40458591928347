.blogEditorWrapper {
    width:100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.blogEditor {
    width: 1000px;
    border: 1px solid #C5C5C5;
    margin-bottom: 10px;
}