.header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.logo{
    max-width: 50%;
    height: auto;
    margin-right: 10px;
}

.header-text{
    font-family: 'Arial Black', sans-serif;
    color: blue;
    font-size: 3em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px #666;
    background-image: linear-gradient(to right, green, red);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (min-width: 900px) {
    .header {
        margin: 40px;
    }
    .logo{
        width: 25%;
    }
}

@media (max-width: 899px) and (min-width: 500px) {
    .header-text{
        font-size: 2em;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}

@media (max-width: 499px) {
    .header-text{
        font-size: 1.8em;
    }
    .header {
        flex-direction: column;
        align-items: flex-start;
    }
    .logo {
        width: 100%;
    }
}
