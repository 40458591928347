.container{
    margin: 0px 20px 20px;
}

.text{
    display: inline;
    width: 100%;
    font-family: "Apple Chancery", sans-serif;
}

.read-or-hide{
    color: blue;
    cursor: pointer;
}

@media (min-width: 768px) {
    .container {
        margin: 0px 150px 20px;
    }
}